/* You can add custom shared styles to this file */
@import 'variables';
@import 'mixins';

.error-message {
  color: $color-danger;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  font-weight: 500;
}

.invalid {
  border-color: $color-danger;
}

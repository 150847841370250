/* Helpers SCSS */
@import 'variables';
@import 'mixins';

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.op-0 {
  opacity: 0;
}

.op-1 {
  opacity: 1;
}
/* display */
.d-block {
  display: block;
}

.d-none {
  display: none;
}

/* flex */
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.grow {
  flex-grow: 0;
}

.grow-1 {
  flex-grow: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.shrink-1 {
  flex-shrink: 1;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.content-stretch {
  align-content: stretch;
}

.item-start {
  align-items: flex-start;
}

.item-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: ($spacer-unit * 0.25);
}

.gap-2 {
  gap: ($spacer-unit * 0.5);
}

.gap-3 {
  gap: ($spacer-unit * 0.75);
}

.gap-4 {
  gap: ($spacer-unit * 1);
}

.gap-5 {
  gap: ($spacer-unit * 1.25);
}

.gap-6 {
  gap: ($spacer-unit * 1.5);
}

.gap-7 {
  gap: ($spacer-unit * 1.75);
}

.gap-8 {
  gap: ($spacer-unit * 2);
}

.gap-9 {
  gap: ($spacer-unit * 2.25);
}

.gap-10 {
  gap: ($spacer-unit * 2.5);
}

/* border */
.rounded-0 {
  border-radius: 0%;
}

.rounded-50 {
  border-radius: 50%;
}

.rounded-100 {
  border-radius: 100%;
}

.rounded-1 {
  border-radius: ($spacer-unit * 0.25);
}

.rounded-2 {
  border-radius: ($spacer-unit * 0.5);
}

/* text */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.text-primary {
  color: $primary-color;
}

.text-secondary {
  color: $secondary-color;
}

.text-black {
  color: $primary-black;
}

.text-white {
  color: $primary-white;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-primary-black {
  color: $primary-black;
}

.bb-5 {
  border-bottom: ($spacer-my * 1.25) solid $primary-color;
}

.cursor-pointer {
  cursor: pointer;
}

/* float */
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

/* width */
.w-0 {
  width: 0;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

/* width */
.h-0 {
  height: 0;
}

.h-50 {
  height: 50%;
}

.h-100 {
  height: 100%;
}

/* Padding */
.p-0 {
  padding: 0;
}

.p-1 {
  padding: ($spacer-unit * 0.25);
}

.p-2 {
  padding: ($spacer-unit * 0.5);
}

.p-3 {
  padding: ($spacer-unit * 0.75);
}

.p-4 {
  padding: ($spacer-unit * 1);
}

.p-5 {
  padding: ($spacer-unit * 1.25);
}

.p-6 {
  padding: ($spacer-unit * 1.5);
}

.p-7 {
  padding: ($spacer-unit * 1.75);
}

.p-8 {
  padding: ($spacer-unit * 2);
}

.p-9 {
  padding: ($spacer-unit * 2.25);
}

.p-10 {
  padding: ($spacer-unit * 2.5);
}

/* padding top */
.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: ($spacer-py * 0.25);
}

.pt-2 {
  padding-top: ($spacer-py * 0.5);
}

.pt-3 {
  padding-top: ($spacer-py * 0.75);
}

.pt-4 {
  padding-top: ($spacer-py * 1);
}

.pt-5 {
  padding-top: ($spacer-py * 1.25);
}

.pt-6 {
  padding-top: ($spacer-py * 1.5);
}

.pt-7 {
  padding-top: ($spacer-py * 1.75);
}

.pt-8 {
  padding-top: ($spacer-py * 2);
}

.pt-9 {
  padding-top: ($spacer-py * 2.25);
}

.pt-10 {
  padding-top: ($spacer-py * 2.5);
}

/* padding bottom */
.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: ($spacer-py * 0.25);
}

.pb-2 {
  padding-bottom: ($spacer-py * 0.5);
}

.pb-3 {
  padding-bottom: ($spacer-py * 0.75);
}

.pb-4 {
  padding-bottom: ($spacer-py * 1);
}

.pb-5 {
  padding-bottom: ($spacer-py * 1.25);
}

.pb-6 {
  padding-bottom: ($spacer-py * 1.5);
}

.pb-7 {
  padding-bottom: ($spacer-py * 1.75);
}

.pb-8 {
  padding-bottom: ($spacer-py * 2);
}

.pb-9 {
  padding-bottom: ($spacer-py * 2.25);
}

.pb-10 {
  padding-bottom: ($spacer-py * 2.5);
}

/* padding left */
.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: ($spacer-px * 0.25);
}

.pl-2 {
  padding-left: ($spacer-px * 0.5);
}

.pl-3 {
  padding-left: ($spacer-px * 0.75);
}

.pl-4 {
  padding-left: ($spacer-px * 1);
}

.pl-5 {
  padding-left: ($spacer-px * 1.25);
}

.pl-6 {
  padding-left: ($spacer-px * 1.5);
}

.pl-7 {
  padding-left: ($spacer-px * 1.75);
}

.pl-8 {
  padding-left: ($spacer-px * 2);
}

.pl-9 {
  padding-left: ($spacer-px * 2.25);
}

.pl-10 {
  padding-left: ($spacer-px * 2.5);
}

/* padding right */
.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: ($spacer-px * 0.25);
}

.pr-2 {
  padding-right: ($spacer-px * 0.5);
}

.pr-3 {
  padding-right: ($spacer-px * 0.75);
}

.pr-4 {
  padding-right: ($spacer-px * 1);
}

.pr-5 {
  padding-right: ($spacer-px * 1.25);
}

.pr-6 {
  padding-right: ($spacer-px * 1.5);
}

.pr-7 {
  padding-right: ($spacer-px * 1.75);
}

.pr-8 {
  padding-right: ($spacer-px * 2);
}

.pr-9 {
  padding-right: ($spacer-px * 2.25);
}

.pr-10 {
  padding-right: ($spacer-px * 2.5);
}

/* Margins */
.m-0 {
  margin: 0;
}

.m-1 {
  margin: ($spacer-unit * 0.25);
}

.m-2 {
  margin: ($spacer-unit * 0.5);
}

.m-3 {
  margin: ($spacer-unit * 0.75);
}

.m-4 {
  margin: ($spacer-unit * 1);
}

.m-5 {
  margin: ($spacer-unit * 1.25);
}

.m-6 {
  margin: ($spacer-unit * 1.5);
}

.m-7 {
  margin: ($spacer-unit * 1.75);
}

.m-8 {
  margin: ($spacer-unit * 2);
}

.m-9 {
  margin: ($spacer-unit * 2.25);
}

.m-10 {
  margin: ($spacer-unit * 2.5);
}

/* margin top */
.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: ($spacer-my * 0.25);
}

.mt-2 {
  margin-top: ($spacer-my * 0.5);
}

.mt-3 {
  margin-top: ($spacer-my * 0.75);
}

.mt-4 {
  margin-top: ($spacer-my * 1);
}

.mt-5 {
  margin-top: ($spacer-my * 1.25);
}

.mt-6 {
  margin-top: ($spacer-my * 1.5);
}

.mt-7 {
  margin-top: ($spacer-my * 1.75);
}

.mt-8 {
  margin-top: ($spacer-my * 2);
}

.mt-9 {
  margin-top: ($spacer-my * 2.25);
}

.mt-10 {
  margin-top: ($spacer-my * 2.5);
}

/* margin bottom */
.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: ($spacer-my * 0.25);
}

.mb-2 {
  margin-bottom: ($spacer-my * 0.5);
}

.mb-3 {
  margin-bottom: ($spacer-my * 0.75);
}

.mb-4 {
  margin-bottom: ($spacer-my * 1);
}

.mb-5 {
  margin-bottom: ($spacer-my * 1.25);
}

.mb-6 {
  margin-bottom: ($spacer-my * 1.5);
}

.mb-7 {
  margin-bottom: ($spacer-my * 1.75);
}

.mb-8 {
  margin-bottom: ($spacer-my * 2);
}

.mb-9 {
  margin-bottom: ($spacer-my * 2.25);
}

.mb-10 {
  margin-bottom: ($spacer-my * 2.5);
}

/* margin left */
.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: ($spacer-mx * 0.25);
}

.ml-2 {
  margin-left: ($spacer-mx * 0.5);
}

.ml-3 {
  margin-left: ($spacer-mx * 0.75);
}

.ml-4 {
  margin-left: ($spacer-mx * 1);
}

.ml-5 {
  margin-left: ($spacer-mx * 1.25);
}

.ml-6 {
  margin-left: ($spacer-mx * 1.5);
}

.ml-7 {
  margin-left: ($spacer-mx * 1.75);
}

.ml-8 {
  margin-left: ($spacer-mx * 2);
}

.ml-9 {
  margin-left: ($spacer-mx * 2.25);
}

.ml-10 {
  margin-left: ($spacer-mx * 2.5);
}

/* margin right */
.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: ($spacer-mx * 0.25);
}

.mr-2 {
  margin-right: ($spacer-mx * 0.5);
}

.mr-3 {
  margin-right: ($spacer-mx * 0.75);
}

.mr-4 {
  margin-right: ($spacer-mx * 1);
}

.mr-5 {
  margin-right: ($spacer-mx * 1.25);
}

.mr-6 {
  margin-right: ($spacer-mx * 1.5);
}

.mr-7 {
  margin-right: ($spacer-mx * 1.75);
}

.mr-8 {
  margin-right: ($spacer-mx * 2);
}

.mr-9 {
  margin-right: ($spacer-mx * 2.25);
}

.mr-10 {
  margin-right: ($spacer-mx * 2.5);
}

/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'helpers';
@import 'mixins';
@import 'fonts';
@import 'customClass';
@import 'customIconClass';

/* rem to px config */
html {
  font-size: $base-font-size; // 1rem =10px
  overscroll-behavior: none;
  scroll-behavior: smooth;

  @include remove-scrollbar;
}

/* General styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  /* Typography - To apply font family automatically */
  font-family: $helvetica-font, sans-serif;
  font-weight: normal;
  font-style: normal;
}

body {
  font-size: $default-font;
  overscroll-behavior: none;
  scroll-behavior: smooth;

  @include remove-scrollbar;
}

p,
button,
input,
textarea,
select,
label,
dd {
  font-size: $medium-font;
}

button {
  font-weight: 600;
}

label {
  font-weight: bold;
}

dt {
  font-size: $default-font;
  font-weight: bold;
}

h1 {
  font-size: $extra-largest-font;
}

h2 {
  font-size: $largest-font;
  font-weight: bold;
}

a:hover {
  cursor: pointer;
}

/* mixins */
@import 'variables';

/* Media query mixin with desktop-first approach as default */
@mixin media($breakpoint, $approach: desktop) {
  @if map-has-key($breakpoints, $breakpoint) {
    @if map-has-key($response-approach, $approach) {
      $approach-type: map-get($response-approach, $approach);

      @if $approach-type == 'mobile' {
        /* Mobile-First approach */
        @media screen and (min-width: (map-get($breakpoints, $breakpoint) + 1px)) {
          @content;
        }
      } @else if $approach-type == 'desktop' {
        /* Desktop-First approach */
        @media screen and (max-width: map-get($breakpoints, $breakpoint)) {
          @content;
        }
      }
    } @else {
      @error "Unknown response approach: #{$approach}. Please use one of: #{map-keys($response-approach)}.";
    }
  } @else {
    @error "Unknown breakpoint: #{$breakpoint}. Please use one of: #{map-keys($breakpoints)}.";
  }
}

/* Frequent layouting mixin */
@mixin full-flex() {
  display: flex;
  width: 100%;
  height: 100%;
}

/* Remove scrollbar */
@mixin remove-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

/* Variables */
$base-font-size: 62.5%;

/* Color pallet */
$primary-color: #3a7bbb;
$secondary-color: #5b5756;
$dark-primary-color: #105683;
$dark-secondary-color: #514f4f;
$light-primary-color: #115683;
$strong-primary-color: #0171bb;
$lightest-primary-color: #d8eafd;
$light-secondary-color: #747474;
$grey: #b1b1b1;
$light-grey: #e2e2e2;
$gray: #aaaaaa;
$extra-light-grey: #f3f3f3;
$lightest-grey: #8e8e8e;
$primary-black: black;
$black: #151515;
$search-boxshadow-black: #0000001c;
$transparent-black: #00000000;
$primary-white: white;
$overlay-black: #00000066;
$white: #ffffff;
$light-black: #383838;
$green: #3abb53;
$green-1: #1ba71b;
$light-green-1: #e7ffe6;
$red: #ff0202;
$red-1: #db5555;
$red-2: #ff2929;
$light-red-1: #ffd8d8;
$color-danger: #dc3545;
$color-success: #008000;
$light-grey-1: #666666;
$light-grey-2: #cccccc;
$light-grey-3: #f0f0f0;
$light-grey-4: #707070;
$light-grey-5: #767676;
$light-grey-6: #a9a9a9;
$light-grey-7: #e1e1e1;
$light-grey-8: #e6e6e6;
$transparent: transparent;
$light-blue: #37a1e8;
$light-yellow-1: #fff4db;
$yellow-1: #dbb255;
$orange: #c96c1e;
$search-dropdown-bg: #ffffff 0% 0% no-repeat padding-box;
$zoom-icons-bg: rgba(0, 0, 0, 0.6);
$green-shade: #c4e8c4;
$blue-shade: #afdfff;
$red-shade: #ffc0ba;
$strong-red: #bc1a0c;
$black-underline: #000000;
$secondary-shade-color: #555759;
$heading-black-color: #222222;
$primary-underline-color: #2671ba;

/* Break points - media query only supports 1rem=16px config */
$breakpoints: (
  ss: 320px,
  mm: 375px,
  ml: 425px,
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  ld: 1024px,
  ll: 1440px,
  xl: 1200px,
  xxl: 1600px
);

/* Response approach */
$response-approach: (
  desktop: 'desktop',
  mobile: 'mobile'
);

/* Transition */
$transition-250ms: 0.25s;
$transition-300ms: 0.3s;

/* Screen */
$mobile-width: 414px;
$mobile-content-width: 372px;
$desktop-content-width: 1150px;

$mobile-content-padding: 20px;
$desktop-content-padding: 32px;

/* Layout */
$header-height: 90px;
$header-height-mobile: 70px;
$utility-header-height: 40px;
$utility-header-height-mobile: 44px;
$global-header-height: calc($header-height + $utility-header-height);
$global-header-height-mobile: calc(
  $header-height-mobile + $utility-header-height-mobile
);
$footer-height: 307px;
$footer-height-mobile: 458px;

/* Field */
$default-border: 0.5px solid $grey;
$default-field-radius: 5px;
$default-field-height: 40px;
$disabled-background: #e1e1e1;

/* Overlay color */
$dark-overlay: #000000ad;
$dark-shadow: #00000012;
$light-overlay: rgba(
  $color: white,
  $alpha: 0.6
);
$black-overlay: rgba(0, 0, 0, 0.8);
$overlay-background: #00000066;
$white-overlay: rgba(255, 255, 255, 0.7);
$modal-overlay-background: rgba(0, 0, 0, 0.5);
$default-box-shadow: 0 1px 12px $dark-shadow;
$normal-box-shadow: 0 0.05px 0.3px $transparent-black;
$dropdown-box-shadown: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
$modal-box-shadow:
  0 3px 6px -4px #0000001f,
  0 6px 16px #00000014,
  0 9px 28px 8px #0000000d;
$profile-card-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.0705882353);
$search-dropdown-box-shadow: 0px 3px 20px $search-boxshadow-black;
$availability-box-shadow: 0px 3px 25px #00000029;
$catalog-section-product-shadow: 0 0.4rem 0.8rem $search-boxshadow-black;
$cart-notification-box-shadow: 0 0 1.5rem #0000001a;
$my-cart-container-box-shadow: rgba(0, 0, 0, 0.05) 0.5px 0.5px 10px 0.5px;
$overlay-image-banner-box-shadow: 0px 3px 15px #0000001a;

/* Font */
$helvetica-font: 'Helvetica';
$fonts: (
  ($helvetica-font, 'LightOblique', 'lighter', 'italic'),
  ($helvetica-font, 'Light', 'lighter', 'normal'),
  ($helvetica-font, 'BoldOblique', 'bolder', 'italic'),
  ($helvetica-font, 'Bold', 'bolder', 'normal'),
  ($helvetica-font, 'BoldOblique', 'bold', 'italic'),
  ($helvetica-font, 'Bold', 'bold', 'normal'),
  ($helvetica-font, 'Oblique', 'normal', 'italic'),
  ($helvetica-font, 'Regular', 'normal', 'normal')
);

/* Fonts size */
$tiny-font: 1rem; // 10px
$smallest-font: 1.1rem; // 11px
$small-font: 1.2rem; // 12px
$moderate-font: 1.3rem; // 13px
$regular-font: 1.4rem; // 14px
$medium-font: 1.5rem; // 15px
$default-font: 1.6rem; // 16px
$mid-font: 1.7rem; // 17px
$large-font: 2rem; // 20px
$larger-font: 2.1rem; // 21px
$extra-larger-font: 2.4rem; // 24px
$extra-large-font: 2.5rem; // 25px
$largest-font: 2.6rem; // 26px
$large-modal-font: 2.9rem; // 29px
$largest-search-font: 2.8rem; // 28px
$search-heading-font: 3.1rem; // 31px
$massive-font: 3.2rem; // 32px
$extra-largest-font: 3.6rem; // 36px
$huge-font: 4.4rem; // 44px
$extra-huge-font: 5.1rem; // 51px
$extra-big-font: 6rem;

/* Spacer variables */
$spacer-x: 1rem;
$spacer-y: 1rem;
$spacer-px: 1rem;
$spacer-py: 1rem;
$spacer-mx: 1rem;
$spacer-my: 1rem;
$spacer-unit: 1rem;

/* Other variables */
$default-card-radius: 12px;

@import 'variables';

/* Auto apply font style according to the font weight */
@mixin get-font-face($name, $type, $weight, $style) {
  $full-type: $name + $type;

  @font-face {
    font-family: $name;
    src: url('../assets/fonts/#{$full-type}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@each $font in $fonts {
  @include get-font-face(
    #{nth($font, 1)},
    #{nth($font, 2)},
    #{nth($font, 3)},
    #{nth($font, 4)}
  );
}

/* You can add custom class icons to this file */
@import 'variables';
@import 'mixins';

/* Custom arrow icons */
.white-arrow-icon {
  display: block;
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 0;
  transform: rotate(-45deg);
  border: 3px solid $primary-white;
  border-top: 0;
  border-left: 0;
  cursor: pointer;
  filter: brightness(0.9);
  transition: filter 0.3s ease;

  @include media(md) {
    border-width: 2px;
  }

  &:hover {
    filter: brightness(1);
  }

  &.right-arrow {
    transform: rotate(-45deg);
  }

  &.left-arrow {
    transform: rotate(135deg);
  }

  &.down-arrow {
    transform: rotate(45deg);
  }

  &.up-arrow {
    transform: rotate(-135deg);
  }
}

/* Custom arrow icons with border and white color fill */
.solid-circle-arrow {
  display: block;
  content: '';
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background-color: $primary-white;
  border: 1px solid $grey;
  cursor: pointer;

  @include media(md) {
    width: 26px;
    height: 26px;
  }

  &:before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    border-radius: 0;
    border: 2px solid $secondary-color;
    border-top: 0;
    border-left: 0;
    cursor: pointer;
    top: 10px;
    left: 10px;

    @include media(md) {
      border-width: 1px;
      width: 7px;
      height: 7px;
      top: 7px;
      left: 7px;
    }
  }

  &.circle-right-arrow {
    transform: rotate(-45deg);
  }

  &.circle-left-arrow {
    transform: rotate(135deg);
  }

  &.circle-down-arrow {
    transform: rotate(45deg);
  }

  &.circle-up-arrow {
    transform: rotate(-135deg);
  }
}

/* Pagination scroll ball used in banner carousel */
.scroll-ball {
  display: block;
  width: 9px;
  height: 9px;
  line-height: 13px;
  border-radius: 50%;
  background-color: $primary-white;
  cursor: pointer;
  transition:
    transform 250ms ease-in-out,
    border 250ms ease-in-out;

  &.active,
  &:hover {
    transform: scale(1.44);
    border: 1px solid $primary-white;
    background-color: transparent;
  }
}

/* Pagination scroll number used in banner carousel */
.scroll-number {
  width: 9px;
  height: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light-grey-8;
  color: $light-grey-8;
  font-size: 0;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: all 250ms ease-in-out;

  @include media(md) {
    width: 4px;
    height: 4px;
  }

  &.active,
  &:hover {
    width: 46px;
    height: 27px;
    font-size: 1.7rem;
    color: $primary-black;
    padding: 0 10px;
    border-radius: 16px / 50%;

    @include media(md) {
      width: 26px;
      height: 16px;
      font-size: 1.1rem;
      border-radius: 8px / 50%;
    }
  }
}

/* Solid Triangle arrow */
.solid-triangle-arrow {
  display: block;
  width: 9px;
  height: 9px;
  cursor: pointer;
  background-color: $primary-white;
  clip-path: polygon(100% 50%, 0 0, 0 100%);

  @include media(md) {
    width: 6px;
    height: 6px;
  }

  &.right-solid-arrow {
    clip-path: polygon(100% 50%, 0 0, 0 100%);
  }

  &.left-solid-arrow {
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
  }

  &.down-solid-arrow {
    clip-path: polygon(50% 100%, 100% 0, 0 0);
  }

  &.up-solid-arrow {
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
  }
}
